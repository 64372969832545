export const str = {
    menu : {
        home: "Startseite",
        categories: "Sortimente",
    },
    home: {
        products: "Produkte",
    },
    categories: {
        category: "Kategorie"
    },
    products: {
        similarProducts: "Ähnliche Produkte"
    },
    generals: {
        category: "Kategorie"
    }
};