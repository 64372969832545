import {useEffect} from "react";

const Modal = ({
                   handleClose,
                   show,
                   title,
                   children,
                   size = 'md',
                   centered = "centered",//empty string if not wished centered
                   scrollable = "",// "scrollable" if wished scrollable
               }) => {

    const escListener = e => {
        if(e.key === "Escape") {
            handleClose();
            document.removeEventListener('keyup', escListener);
        }
    }
    useEffect(() => {
        if(show) {
            document.addEventListener('keyup', escListener);
        }
    }, [show]);

    return (
        <div className={`modal fade ${show ? "show d-block" : ""}`}
             
             aria-hidden="true">
            <div className={`modal-dialog modal-${size} modal-dialog-${centered} modal-dialog-${scrollable}`}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-secondary">
                            {title}
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                onClick={handleClose}></button>
                    </div>
                    <div className="modal-body">
                        {children}
                    </div>
                    {/*<div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleClose}>Close</button>
                        <button type="button" className="btn btn-primary">Save changes</button>
                    </div>*/}
                </div>
            </div>
        </div>
    );
}
export default Modal