import {Navigation} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import {memo, useContext, useEffect, useState} from "react";
import {NavItem} from "../Layout";
import useFitText from "../../hooks/useFitText";
import {truncateString} from "../../utils/functions";
import CategoriesProvider from "../../providers/CategoriesProvider";
import axios from "axios";
import {apiRoute} from "../../utils/dynamicStrings";
import {routes} from "../../utils/routes";
import {Spinner} from "react-bootstrap";

const SecondaryMenuSwiper = () => {
  
  const [loading, setLoading] = useState(true);
  const {categories, setCategories} = useContext(CategoriesProvider);
  useEffect(() => {
    axios.get(apiRoute(routes.categories.url))
      .then(res => setCategories(res.data.data))
      .catch(error => console.log("Something went wrong"));
    
  }, [setCategories]);
  
  return (
    <div className="container mt-1 secondary-navbar">
      <div className={`bg-light `} style={{height: 55}}>
        {loading && <div className={"text-center h-100 vstack justify-content-center"}>
          <Spinner className={"m-auto text-muted"}/>
        </div>}
        <Swiper
          className={loading?"opacity-0":"opacity-1 menu-slider"}
          breakpoints={{
            360: {
              slidesPerView: 2,
            },
            // when window width is >= 576px
            576: {
              slidesPerView: 3,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 4,
            },
            1024: {
              slidesPerView: 6,
            }
          }}
          modules={[Navigation]}
          spaceBetween={5}
          navigation
          pagination={{clickable: true}}
          scrollbar={{draggable: true}}
          onSwiper={(swiper) => {
            //console.log(swiper); TODO: why this gets run so many times??
            setLoading(false);
          }}
          onSlideChange={() => console.log('slide change')}
          onSlideChangeTransitionStart={() => {
            console.log("disable clicking...")
          }}
          onSlideChangeTransitionEnd={() => {
            console.log("... enable clicking")
          }}
        >
          {
            categories.map(c => <SwiperSlide key={c.id}>
              <NavItem key={c.slug} url={"/categories/".concat(c.slug)}
                       extraCls={"text-black menu-categ h-100 text-center px-2 border-1 border-secondary border"}
                       linkExtraCls={"vstack justify-content-center h-100"}
              >
                <ItemText text={c.name}/>
              </NavItem>
            </SwiperSlide>)
          }
        </Swiper>
      </div>
    </div>
  
  )
}
export default memo(SecondaryMenuSwiper);


const ItemText = ({text}) => {
  const {fontSize, ref} = useFitText();
  return (
    <div ref={ref} className={""} style={{fontSize}} title={text} >
      {truncateString(text, 22)}
    </div>
  )
}