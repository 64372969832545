import Cookies from 'universal-cookie';

import CryptoJS from "crypto-js";
import {config} from "./config";
import {User} from "../models/user";

const cookies = new Cookies();

export const decipher = (ciphered:string) => {
  try {
    const bites = CryptoJS.AES.decrypt(ciphered, config.secretKey);
    return bites.toString(CryptoJS.enc.Utf8);
  } catch (e) {
    return null;
  }
  
}

export const logout = setUserCallback => {
  cookies.remove(config.keys.auth);
  setUserCallback(null);
}

export const authenticate = (user:User, setUserCallback) => {
  const str:string = userString(user);
  const ciphered:string = userCiphered(str);
  cookies.set(config.keys.auth, ciphered);
  setUserCallback(user);
}

export const getUser = () => {
  try {
    const ciphered:string = cookies.get<string>(config.keys.auth);
    const userStr = decipher(ciphered);
    return userJson(userStr);
  } catch (e) {
    return null;
  }
}

export const getToken = () => {
  const user:User = getUser();
  return user?.token??null;
}

const userCiphered = (user:string) => CryptoJS.AES.encrypt(user, config.secretKey).toString();

const userString = (user:User) => JSON.stringify(user)

const userJson = (user:string) => {
  try {
    return JSON.parse(user) as User;
  } catch (e) {
    return null;
  }
}
