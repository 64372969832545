import React, {useContext, useState} from "react";
import OffCanvas from "../partials/OffCanvas";
import {AuthCanvasProvider} from "../../providers/AuthCanvasProvider";
import {BsPersonFill} from "react-icons/bs";
import * as auth from '../../utils/auth';
import {AuthProvider} from "../../providers/AuthProvider";

export const AuthCanvas = () => {
  const {showAuthCanvas, setShowAuthCanvas} = useContext(AuthCanvasProvider);
  const {user, setUser} = useContext(AuthProvider);
  if(!user) return(<></>);
  return (
    <>
      <OffCanvas
        heading={user.name}
        show={showAuthCanvas}
        handleClose={() => setShowAuthCanvas(false)}
      >
        <div className="text-center">
          <BsPersonFill size={80} className={"m-3 mb-0 text-black-50"} />
          <div className=" mb-4">{user.email}</div>
          <button type={"button"}
                  onClick={() => {
                    auth.logout(setUser);
                    setShowAuthCanvas(false);
                  }}
                  className={"btn btn-outline-warning mb-2"}>Logout</button>
        </div>
      
      </OffCanvas>
    </>
  );
}