import {createContext} from "react";
import {Category} from "../models/category";

type ICProvider =  {
  categories? : Category[],
  setCategories?: any
}

const CategoriesProvider = createContext<ICProvider>({});

export default CategoriesProvider;