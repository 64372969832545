import '../styles/globals.css'
import 'bootstrap/dist/css/bootstrap.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Layout from "../components/Layout";
import {useEffect, useMemo, useState} from "react";
import {AuthProvider} from "../providers/AuthProvider";
import CategoriesProvider from "../providers/CategoriesProvider";
import * as auth from "../utils/auth";
import {AuthCanvasProvider} from "../providers/AuthCanvasProvider";

export default function App({Component, pageProps}) {

  const [user, setUser] = useState(null);
  const authValue = useMemo(() => ({user, setUser}), [user]);

  const [categories, setCategories] = useState([]);
  const categoriesValue = useMemo(() => ({categories, setCategories}), [categories]);
  
  const [showAuthCanvas, setShowAuthCanvas] = useState(false);
  
  
  
  useEffect(() => {
    setUser(auth.getUser())
  }, [])


  return (
    <AuthProvider.Provider value={authValue}>
      <CategoriesProvider.Provider value={categoriesValue}>
        <AuthCanvasProvider.Provider value={{showAuthCanvas, setShowAuthCanvas}} >
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </AuthCanvasProvider.Provider>
      </CategoriesProvider.Provider>
    </AuthProvider.Provider>
  )
}
