import {slugify} from "../../utils/functions";

export const AInput = ({text, type = "text", id = null, field = null, inputRef = null, ...inputParams}) => {
    const nField = field ?? slugify(text)
    const nId = id ?? "id-" + nField
    return (
        <div className="form-floating mb-2 text-black-50">
            <input type={type} name={nField} id={nId}
                   placeholder={inputParams.placeholder??text}
                   className={"form-control"} {...inputParams} ref={inputRef}/>
            <label htmlFor={nId} className="form-label">
                {text}
            </label>
        </div>
    )
}


export const ASubmit = ({text = "Save", variant = "primary", ...inputParams}) => {

    return (
        <div className="form-group mb-2 text-end">
            <button type={"submit"} className={`btn btn-${variant}`} {...inputParams}>{text}</button>
        </div>
    )
}
