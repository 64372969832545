import React, {useState} from "react";
/*
* make sure to include this in a proper element, e.g. not in an ul or nav
* */
const OffCanvas = ({show, heading, handleClose, children, position = 'start'}) => {
  return (
    <>
      <div className={`offcanvas offcanvas-${position} ${show ? "show" : ""}`}>
        <div className="offcanvas-header">
          {heading && <h3 className="offcanvas-title">{heading}</h3>}
          <button type="button" className="btn-close text-reset" onClick={handleClose}></button>
        </div>
        <div className="offcanvas-body">
          {children}
        </div>
      </div>
      {show && <div className={`offcanvas-backdrop fade show `} onClick={handleClose}></div>}
    </>
  )
}

export default OffCanvas;


export const OffCanvasSample = () => {
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  return (
    <>
      <OffCanvas
        heading={"WAit"}
        show={showOffCanvas}
        handleClose={() => setShowOffCanvas(false)}
      >
        Testing </OffCanvas>
      <button type={"button"} className={"btn btn-primary mb-2"} onClick={() => setShowOffCanvas(true)}>OffCanvas</button>
    </>
  );
}