import React, {useContext, useEffect, useRef, useState} from "react";
import {useRouter} from "next/router";
import {AuthProvider} from "../providers/AuthProvider";
import Modal from '../components/partials/Modal'
import CategoriesProvider from "../providers/CategoriesProvider";
import axios from "axios";
import {apiRoute} from "../utils/dynamicStrings";
import {routes} from "../utils/routes";
import {AInput, ASubmit} from "./partials/form";
import * as auth from "../utils/auth";
import Link from "next/link";
import {INavItem} from "../models/generals";
import {AuthCanvas} from "./auth/AuthCanvas";
import {AuthCanvasProvider} from "../providers/AuthCanvasProvider";
import SecondaryMenuSwiper from "./products/SecondaryMenuSwiper";
import {alog} from "../utils/logHelpers";
import {str} from "../utils/str";


export default function Layout({children}) {
  
  const r = useRouter();
  
  alog("layout render ...".concat(r.asPath));
  return (
    <div className={'min-vh-100'}>
      <AuthCanvas/>
      <MyNav/>
      <div className=" ps-0 d-flex ">
        <div className="container-fluid ">
          <div className={'container pt-2'}>{children}</div>
        </div>
      </div>
    </div>
  );
}


const MyNav = () => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark a-nav-bg">
        <div className="container">
          <Link className="navbar-brand d-md-none" href="/">Hamta</Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                  aria-expanded={isNavCollapsed ? "true" : "false"}
                  onClick={handleNavCollapse}
                  aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`navbar-collapse ${isNavCollapsed ? "collapse" : ""}`} id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <NavItem url={"/"}>{str.menu.home}</NavItem>
              <NavItem url={"/categories"}>{str.menu.categories}</NavItem>
            </ul>
            {/*<form className="d-flex">
            <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
              <button className="btn btn-outline-light" type="submit">Search</button>
          </form>*/}
            {/*<AuthButtons/> TODO! hiding for now*/}
          </div>
        </div>
        {/*END .container*/}
      </nav>
      <SecondaryMenuSwiper/>
    </>
  )
}

const SecondaryNav = () => {
  const {categories, setCategories} = useContext(CategoriesProvider);
  useEffect(() => {
    axios.get(apiRoute(routes.categories.url))
      .then(res => setCategories(res.data.data))
      .catch(error => console.log("Something went wrong"));
  }, []);
  
  
  return (
    <nav className="navbar navbar-expand-lg navbar-light shadow-sm">
      <div className="container">
        <div className={`navbar-collapse`} id="navbarSupportedContent2">
          <ul className="secondary-navbar navbar-nav me-auto mb-2 mb-lg-0 a-flex-scroll-x  a-h-40">
            {
              categories.map(c => <NavItem key={c.slug} url={"/categories/".concat(c.slug)}
                                           extraCls={"text-black me-2 menu-categ"}>{c.name}</NavItem>)
            }
          </ul>
          
        </div>
      </div>
      {/*END .container*/}
    </nav>
  )
}


export const NavItem = ({url, children, callback, extraCls = "", linkExtraCls= ""}: INavItem) => {
  const r = useRouter()
  const [active, setActive] = useState("");
  
  useEffect(() => {
    if (r.pathname === url || r.asPath === url) setActive("active");
    else
    if (r.pathname.match("categories") && url === "/categories") setActive("active");
    else
    if (url.match("/*/".concat(r.query.id as string))) setActive("active");
    else setActive("");
    
  })
  
  const click = callback ?? null
  
  return (
    <li className={`nav-item ${extraCls}`} >
      <Link href={url} className={`nav-link ${active} ${linkExtraCls}`} onClick={click}>
        {children}
      </Link>
    </li>
  )
}

const AuthButtons = () => {
  const {user} = useContext(AuthProvider);
  const [showModal, setShowModal] = useState(false);
  const {setShowAuthCanvas} = useContext(AuthCanvasProvider);
  return (
    <>
      <ul className="nav navbar-nav mb-auto float-end ">
        {user && <NavItem url={'#'} callback={() => setShowAuthCanvas(true)}>User</NavItem>}
        {!user && <NavItem url={'#'} callback={() => setShowModal(true)}>Login</NavItem>}
        {!user && <Modal
          title={"Login"}
          size={'md'}
          show={showModal}
          handleClose={() => setShowModal(false)}
        >
          <LoginForm/>
        </Modal>}
      </ul>
    </>
  
  )
}


const LoginForm = () => {
  const emailRef = useRef(null);
  const {setUser} = useContext(AuthProvider);
  const handleSubmit = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    axios.post(apiRoute(routes.login.url), data, {
      headers: {
        "Accept" : 'application/json',
        "Content-Type" : 'application/json',
        'User-Agent': '*',
      }
  
    })
      .catch(error => console.log(error))
      .then(res => {
        if (typeof res !== "undefined") {
          if (res.status === 200) {
            auth.authenticate(res.data.data, setUser);
          }
        }
      });
  }
  useEffect(() => {
    if (emailRef && emailRef.current) {
      emailRef.current.focus();
    }
  }, [emailRef])
  
  return (
    <form onSubmit={handleSubmit}>
      <AInput field={'email'} text={"Email"} type={'email'}/>
      <AInput text={"Password"} field={'password'} type={'password'}/>
      <ASubmit variant={"success"} text={"Login"}/>
    </form>
  )
}
