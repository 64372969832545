// const base = "http://api.hamta.lan/api/";
const base = "https://api.hamta.at/api/";
export const routes = {
  products : {
    url: base.concat("products"),
    method: "get"
  },
  product : {
    url: base.concat("products/single/:slug?single=1"),
    method: "get"
  },
  categories : {
    url: base.concat("categories"),
    method: "get"
  },
  category : {
    url: base.concat("categories/single/:slug?single=1"),
    method: "get"
  },
  login : {
    url: base.concat("auth/login"),
    method: "post"
  },
  logout : {
    url: base.concat("auth/logout"),
    method: "post"
  },
}